<template>
  <div class="w-full">
    <vs-table class="w-auto relative" ref="table" v-model="selected">
      <template #thead>
        <vs-tr>
          <vs-th class="!w-2">
            <i class="bx bx-check" />
          </vs-th>
          <vs-th class="!w-1/4"> Name </vs-th>
          <vs-th class="!w-1/4"> Game </vs-th>
          <vs-th> Verify Status </vs-th>
          <vs-th> Created At</vs-th>
        </vs-tr>
      </template>
      <template #tbody v-if="products">
        <vs-tr
          :key="i"
          v-for="(tr, i) in products.docs"
          :data="tr"
          class="cursor-pointer"
          :is-selected="selected === tr"
        >
          <vs-td>
            <i v-if="!checkReady(tr)" class="bx bx-x"></i>
            <i v-else class="bx bx-check"></i>
          </vs-td>

          <vs-td>
            {{
              tr.name.length > NAME_MAX_LENGTH
                ? tr.name.substring(0, NAME_MAX_LENGTH) + "..."
                : tr.name
            }}
          </vs-td>
          <vs-td>
            {{
              tr.game.name.length > NAME_MAX_LENGTH
                ? tr.game.name.substring(0, NAME_MAX_LENGTH) + "..."
                : tr.game.name
            }}
          </vs-td>
          <vs-td>
            <i v-if="!checkVerify(tr)" class="bx bx-x"></i>
            <i v-else class="bx bx-check"></i>
          </vs-td>
          <vs-td>
            {{ tr.createdAt | formatTimestamp }}
          </vs-td>
        </vs-tr>
      </template>
      <template #footer v-if="products">
        <vs-pagination v-model="page" :length="products.totalPages" />
      </template>
    </vs-table>
  </div>
</template>

<script>
import { getProducts } from "@/services/ApiService";

export default {
  name: "Products",
  props: {
    store: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    page: 1,
    max: 10,
    products: null,
    selected: null,
    NAME_MAX_LENGTH: 20,
  }),
  methods: {
    async getProducts() {
      const loading = this.$vs.loading({
        target: this.$refs.table,
        background: "rgba(0, 0, 0, 0.6)",
        color: "primary",
        type: "points",
      });
      this.products = await getProducts(this.store._id, this.page)
        .then((response) => response)
        .catch(() => {
          this.$vs.notification({
            color: "danger",
            name: "Error",
            text: "Something went wrong!",
          });
          loading.close();
        });
      loading.close();
    },
    checkVerify(tr) {
      return tr.active;
    },
    checkReady(tr) {
      return tr.ready;
    },
  },
  async mounted() {
    await this.getProducts();
  },
  watch: {
    page() {
      this.getProducts();
    },
    selected: {
      handler() {
        const id = this.selected._id;
        this.$router.push({
          name: "createProduct",
          params: { id },
        });
      },
      deep: true,
    },
  },
};
</script>

<style scoped>

</style>
